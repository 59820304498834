<template>
  <el-dialog
    title="确认发货"
    :visible.sync="dialogVisible"
    width="50%"
    :before-close="handleClose"
    :modal-append-to-body="false"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="发货地址" prop="shipperId">
        <el-select v-model="ruleForm.shipperId" placeholder="请选择发货地址">
          <el-option
            :label="item.adddress"
            :value="item.id"
            v-for="item in shipperList"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="快递公司" prop="expressCode">
        <el-select v-model="ruleForm.expressCode" placeholder="请选择快递公司">
          <el-option
            :label="item.expressName"
            :value="item.expressCode"
            v-for="item in expressList"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="物流单号" prop="expressNo">
        <el-input
          v-model="ruleForm.expressNo"
          placeholder="请输入快递单号"
          style="width: 350px"
        ></el-input>
      </el-form-item>
      <el-form-item label="运费" prop="freightAmount">
        <el-input
          v-model="ruleForm.freightAmount"
          style="width: 350px"
        ></el-input>
      </el-form-item>
      <el-form-item label="收货人:">
        {{ orderDetail.receiverName }}
      </el-form-item>
      <el-form-item label="手机号:">
        {{ orderDetail.receiverPhone }}
      </el-form-item>
      <el-form-item label="收货地址:">
        {{ orderDetail.receiverProvince + orderDetail.receiverCity+orderDetail.receiverRegion+ orderDetail.receiverDetailAddress }}
      </el-form-item>
      <el-form-item style="height: 120px">
        <div class="desc">
          <div v-for="item in tableData" :key="item.id">
            <div>店铺名称: {{ item.storeName }}</div>
            <div>联系人: {{ item.storeContact }}</div>
            <div>发货地址: {{ item.storeAddress }}</div>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="resetForm">取 消</el-button>
      <el-button type="primary" @click="submitForm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { shipperList } from "@/api/shipper";
import { expressList } from "@/api/express";
import { orderDetail, orderDelivery } from "@/api/order";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
   const freight = (rule, value, callback) => {
     const r = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/ // 不能为负数
     if (value == null || String(value).trim() === "") {
       callback(new Error("不能为空"));
     } else if (value <= 0) {
       callback(new Error("运费必须大于0"));
     } else if (!r.test(value)) {
       callback(new Error("请输入数字"));
     } else {
       callback();
     }
   };
    return {
      dialogVisible: true,
      ruleForm: {
        shipperId: "", // 发货地址
        expressCode: "", // 快递公司
        expressNo: "", //快递单号
        freightAmount: "", //运费
        orderCombineId: this.id, //订单id
      },
      tableData: [],
      rules: {
        shipperId: [
          { required: true, message: "请输入发货地址", trigger: "blur" },
        ],
        expressCode: [
          { required: true, message: "请选择快递公司", trigger: "change" },
        ],
        expressNo: [
          { required: true, message: "请填写物流单号", trigger: "blur" },
        ],
        freightAmount: [
          { required: true,  validator: freight, trigger: "blur" },
        ],
      },
      shipperList: [], //地址列表
      expressList: [], //快递公司列表
      orderDetail: {}, //订单详情
    };
  },

  created() {
    //发货地址列表查询
    shipperList().then((res) => {
      this.shipperList = res.data.list;
    });
    //快递公司列表查询
    expressList().then((res) => {
      this.expressList = res.data.list;
    });
    // 订单详情
    orderDetail({ id: this.id }).then((res) => {
      this.orderDetail = res.data;
      this.tableData = res.data.orderList;
    });
  },

  methods: {
    //确认关闭弹框
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.$emit("closeDialog");
        })
        .catch((_) => {});
    },
    // 确认按钮
    submitForm(formName) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.arr = {
            ...this.ruleForm,
            freightAmount: this.ruleForm.freightAmount,
          };
          if (this.arr.freightAmount > 0) {
            orderDelivery(this.arr).then((res) => {
              this.$emit("resetForm");
            });
          } else {
            this.$message.error("运费不能小于0.00");
          }
        } else {
          return false;
        }
      });
    },
    // 取消按钮
    resetForm() {
      this.$emit("resetForm");
    },
  },
};
</script>


<style lang='less' scoped>
.desc {
  height: 120px;
  border: 1px solid #ccc;
  padding-left: 10px;
  overflow: scroll;
}
</style>
