<template>
  <!-- 角色管理 -->
  <div>
    <div class="nav">
      <div>
        <!-- 查询 -->
        <div>
          <div class="search_box">
            <div class="search_main">
              <div class="status">
                <div class="text">下单时间</div>
                <div>
                  <el-date-picker v-model="datatime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd 00:00:00">
                  </el-date-picker>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div class="text">订单状态</div>
                <div>
                  <el-select v-model="queryinfo.orderStatus">
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="(item, index) in orderStatus" :key="index" :label="item" :value="index">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div class="text" style="margin-left: 32px">订单来源</div>
                <div>
                  <el-select v-model="queryinfo.sourceType">
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="(v, k) in source" :key="k" :label="v" :value="k"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <br />
            <div class="search_main">
              <div class="status">
                <div class="text">用户账号</div>
                <div>
                  <el-input style="width: 260px" v-model="queryinfo.memberMobile" placeholder="请输入内容">
                  </el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div class="text">订单号</div>
                <div>
                  <el-input placeholder="请输入内容" v-model="queryinfo.combineOrderNo" style="width: 260px"></el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div class="text" style="width: 160px; margin-left: -70px">
                  收货人手机号
                </div>
                <div>
                  <el-input v-model="queryinfo.receiverPhone" placeholder="请输入内容" style="width: 260px"></el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status1" style="display: flex">
                <div class="demandBtn" @click="search">
                  <img src="../../assets/images/search.png" alt="" /> 查询
                </div>
                <div class="resetBtn" @click="reset">
                  <img src="../../assets/images/reset.png" alt="" />重置
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 查询 -->
      </div>
    </div>
    <div style="height: 20px"></div>
    <div class="body" style="height: 560px">
      <!-- 列表 -->
      <div class="dataTable">
        <el-table ref="multipleTable" :data="tableData" border tooltip-effect="dark" style="width: 100%; font-family: PingFangRoutine" :header-cell-style="tableHeaderColor" :cell-style="{ color: '#2C2C2C', fontSize: '14px' }">
          <el-table-column prop="combineOrderNo" label="订单号" align="center" width="180" />
          <el-table-column prop="memberMobile" label="用户账号" align="center" width="120"/>
          <el-table-column prop="receiverName" label="收货人" align="center" />
          <el-table-column prop="receiverPhone" label="收货人手机号" align="center"  width="120">
          </el-table-column>
          <el-table-column prop="totalAmountStr" label="订单金额" align="center" width="120">
            <template #default="scope">￥{{ scope.row.totalAmountStr }}</template>
          </el-table-column>
          <el-table-column prop="freightAmountStr" label="运费" align="center" width="120">
            <template #default="scope">￥{{ scope.row.freightAmountStr }}</template>
          </el-table-column>
          <el-table-column prop="payAmountStr" label="实付金额" align="center" width="120">
            <template #default="scope">￥{{ scope.row.payAmountStr }}</template>
          </el-table-column>
          <el-table-column prop="sourceType" label="订单来源" align="center" width="120">
            <template #default="scope">
              <div v-if="scope.row.sourceType == 0">{{ "APP" }}</div>
              <div v-if="scope.row.sourceType == 1">{{ "小程序" }}</div>
              <div v-if="scope.row.sourceType == 2">{{ "PC" }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="下单时间" align="center" width="170">
          </el-table-column>
          <el-table-column prop="payType" label="支付方式" align="center" width="120">
            <template #default="scope">
              <div v-if="scope.row.payType == 0">{{ "未支付" }}</div>
              <div v-if="scope.row.payType == 1">{{ "白条" }}</div>
              <div v-if="scope.row.payType == 2">{{ "支付宝" }}</div>
              <div v-if="scope.row.payType == 3">{{ "微信" }}</div>
              <div v-if="scope.row.payType == 4">{{ "农行" }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="forbidden" label="订单状态" align="orderStatus" width="120">
            <template slot-scope="scope">
              <span v-show="scope.row.orderStatus == 1 && !scope.row.cancelReason">已取消</span>
              <el-popover style="height: 80px" v-show="scope.row.orderStatus == 1 && scope.row.cancelReason" placement="bottom" width="150" trigger="hover">
                <div style="background-color: transparent" slot="reference">
                  {{ fn(scope.row.orderStatus) }}
                </div>
                <span>{{ scope.row.cancelReason }}</span>
              </el-popover>
              <div v-show="scope.row.orderStatus !== 1" :class=" scope.row.orderStatus == 0 ? 'red' : scope.row.orderStatus == 2 ? 'red' : 'black'" v-for="(item, index) in orderStatus">
                <span v-show="scope.row.orderStatus == index">
                  {{ scope.row.orderStatus == index ? item : "" }}
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="200" align="center" class-name="small-padding fixed-width"  fixed="right">
            <template #default="scope">
              <div style="display: flex; justify-content: center">

                <el-button type="text" @click="orderdeta(scope.row.id, scope.row.orderStatus)" >详情</el-button>
                <el-button type="text" v-if="scope.row.orderStatus == 2 ? true : ''"  @click="block(scope.row.id)" >发货</el-button>
<!--                <el-button type="text" v-if="scope.row.orderStatus == 2 ? true : ''"  @click="cancelId(scope.row.id)" >取消</el-button>-->
                <el-button type="text"   @click="cancelId(scope.row.id)" >取消</el-button>
                <el-button type="text" v-if="scope.row.settleStatus == 0 &&  [2 ,3,4,5].includes(scope.row.orderStatus)"  @click="doOrderSettleAction(scope.row.id)" >结算</el-button>


                <el-popover style="margin-left: 10px;" ref="popover" placement="bottom" :width="175" trigger="click" title="商品">
                  <div v-for="(item, index) in goodssketchs" :key="index" class="goodssketch">
                    {{ item }}
                  </div>
                  <el-button type="text" @click="selectStyle(scope.row.id)" slot="reference">明细</el-button>
                </el-popover>

              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 列表 -->
    </div>
    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination background @current-change="handleCurrentChange" :current-page="1" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px">确定</div>
      </div>
      <!-- 分页 -->
    </div>
    <!-- 取消订单 -->
    <div>
      <el-dialog title="取消订单" :visible.sync="dialogFormVisible" width="35%" :modal-append-to-body="false">
        <el-form :model="cancelForm" label-width="100px" label-position="right" :rules="rules" ref="inserform">
          <el-form-item label="取消原因" prop="reason">
            <el-input type="textarea" v-model="cancelForm.reason"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addCancel('inserform')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 取消订单 -->

    <!-- 确认订单 -->
    <diolog v-if="isShow" @resetForm="resetForm" :id="orderId" @closeDialog="closeDialogFn"></diolog>
  </div>
</template>
<script>
import {orderList, orderStatus, orderPayment, orderCancel, orderFloat, orderSource, deleteOrder, doOrderSettle} from "@/api/order";
import diolog from "./dialog.vue";
import "../../font-style/font.css";
export default {
  data() {
    return {
      visible: false,
      datatime: [], //日期
      goodssketch: "",
      goodssketchs: [],
      orsta: "",
      orsue: "",
      orderStatus: ["待支付", "已取消", "待发货", "已发货", "待评价", "已完成"],
      source: ["Pc", "App", "小程序"],
      payment: [],
      queryinfo: {
        startDate: "",
        endDate: "",
        createTime: "",
        sourceType: "",
        memberMobile: "",
        orderStatus: "",
        combineOrderNo: "",
        receiverPhone: "",
        currPage: "",
      },
      tableData: [],
      total: null,
      dialogFormVisible: false,
      cancelForm: {
        id: "",
        reason: "",
      },
      rules: {
        reason: [
          {
            required: true,
            message: "请填写取消原因",
            trigger: "blur",
          },
        ],
      },
      isShow: false, // 确认订单弹框
      orderId: "", //订单id
      memberId: "",
    };
  },
  components: {
    diolog,
  },
  activated() {
    this.list();
  },
  methods: {
    fn(status) {
      return this.orderStatus[status];
    },
    //订单列表
    list() {
      orderList(this.queryinfo).then((res) => {
        this.tableData = res.data.list;
        this.tableDatas = res.data.list;
        this.total = res.data.total * 1;
      });
      //订单状态
      // orderStatus().then((res) => {
      //   this.orderStatus = res.data;
      //   this.orderStatus[4]='待评价',
      //   this.orderStatus[5]='已完成'
      // });
      //支付方式
      orderPayment().then((res) => {
        this.payment = res.data;
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.queryinfo.currPage = val;
      this.list();
    },
    // table
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;;fontSize: '14px'";
      }
    },
    //搜索
    search() {
      this.queryinfo.startTime = this.datatime[0];
      this.queryinfo.endTime = this.datatime[1];
      this.queryinfo.currPage = ""
      this.total = 0
      this.list();
    },
    // 重置
    reset() {
      this.total=0
      this.datatime = [];
      this.queryinfo.sourceType = ""
      this.queryinfo.memberMobile = ""
      this.queryinfo.orderStatus = ""
      this.queryinfo.combineOrderNo = ""
      this.queryinfo.receiverPhone = ""
      this.queryinfo.currPage = ""
      this.queryinfo.startTime = "";
      this.queryinfo.endTime = "";
      this.list();
    },
    //详情页
    orderdeta(id, orderStatus) {
      this.$router.push({
        path: "/order/detail",
        query: {
          id: id,
          orderStatus: orderStatus,
        },
      });
    },

    // 商品简述
    selectStyle(id) {
      orderFloat({
        id: id,
      }).then((res) => {
        this.goodssketchs = [];
        if(res.data){
          res.data.forEach((item, index) => {
          if (index <= 4) this.goodssketchs.push(item);
        });
        }
      });
    },
    doOrderSettleAction(id){
      doOrderSettle(id).then((res) => {
        if(res.status == 10000){
          this.$message.success("操作成功");
          this.list();
        }
      });
    },
    //获取取消订单id
    cancelId(id) {
      this.dialogFormVisible = true;
      this.cancelForm.id = id;
      this.cancelForm.reason = "";
    },
    //取消订单
    addCancel() {
      this.$refs.inserform.validate((valid) => {
        if (valid) {
          orderCancel(this.cancelForm).then((res) => {
            if (res.status == 10000) {
              this.dialogFormVisible = false;
               this.list()
            }
          });
        } else {
          return;
        }
      });
    },
    // 确认发货
    block(val) {
      this.isShow = true;
      this.orderId = val.id;
      this.list()
    },
    deldeta(val) {
      //删除订单
      console.log(val);
      deleteOrder({ combineOrderId: val.id }).then((res) => {
        if (res.status == 10000 && res.data == true) {
          this.$notify.success({
            title: "Info",
            message: "删除订单成功",
            showClose: false,
          });
          this.list();
          this.total = 0;
        }
        console.log(res);
      });
    },
    // 关闭确认发货框
    closeDialogFn(val) {
      this.isShow = false;
    },
    // 确认发货取消 完成
    resetForm() {
      this.isShow = false;
      this.list();
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/common.less";

/deep/ .el-table__cell.is-center {
  text-align: left;
}

/deep/ .cell {
  text-align: center;
}

#el-popover-2419 {
  width: 220px !important;
  z-index: 9999;
}

.operabtn {
  width: 60px;
  height: 30px;
  background: #279dfb;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  margin-right: 0;

  img {
    margin-top: 7px;
    width: 18px;
    height: 16px;
  }
}

.xinxibtn {
  width: 60px;
  height: 30px;
  background: #fc9714;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  margin-left: 10px;

  img {
    margin-top: 7px;
    width: 18px;
    height: 16px;
  }
}

.delete {
  width: 60px;
  height: 30px;
  background: #fb6a57;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  margin-left: 10px;

  i {
    font-size: 18px;
    color: white;
    margin-top: 6px;
    width: 18px;
    height: 16px;
  }
}

.quxiaobtn {
  width: 60px;
  height: 30px;
  background: #ffcc33;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  margin-left: 10px;

  img {
    margin-top: 7px;
    width: 18px;
    height: 16px;
  }
}

.querenbtn {
  width: 60px;
  height: 30px;
  background: #18bea4;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  margin-left: 10px;

  img {
    margin-top: 7px;
    width: 18px;
    height: 16px;
  }
}

.status > .text:nth-child(1) {
  width: 57px;
  text-align: right;
}

.status > .text:nth-child(2) {
  margin-left: 19px;
}
</style>
